/**
 * --------------------------------------------------------------------------
 * Bootstrap (v5.0.2): index.umd.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * --------------------------------------------------------------------------
 */

import Alert from './assets/src/alert'
import Button from './assets/src/button'
import Carousel from './assets/src/carousel'
import Collapse from './assets/src/collapse'
import Dropdown from './assets/src/dropdown'
import Modal from './assets/src/modal'
import Offcanvas from './assets/src/offcanvas'
import Popover from './assets/src/popover'
import ScrollSpy from './assets/src/scrollspy'
import Tab from './assets/src/tab'
import Toast from './assets/src/toast'
import Tooltip from './assets/src/tooltip'

export default {
  Alert,
  Button,
  Carousel,
  Collapse,
  Dropdown,
  Modal,
  Offcanvas,
  Popover,
  ScrollSpy,
  Tab,
  Toast,
  Tooltip
}
